var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isIntegration && _vm.isUser)?_c('div',{staticClass:"navbar-end"},[(!_vm.$motors)?_c('AppHeaderEndSwitch'):_vm._e(),(_vm.$motors)?_c('AppHeaderEndLinks'):_vm._e(),(_vm.isUser)?_c('div',{staticClass:"navbar-menu",attrs:{"data-test-id":"username-dropdown"}},[_c('div',{staticClass:"navbar-link dropdown is-hoverable is-arrowless is-right"},[_c('div',{staticClass:"dropdown-trigger"},[_c('a',{staticClass:"nav-menu-item",class:{ 'is-active': _vm.$route.name === 'account-details' },attrs:{"to":{ name: 'account-details' }}},[_c('span',[_c('strong',[_vm._v(" "+_vm._s(_vm.initials)+" ")])]),_vm._m(0)])]),_c('div',{staticClass:"dropdown-menu has-arrow is-right",attrs:{"role":"menu"}},[_c('div',{staticClass:"dropdown-content"},[_c('router-link',{staticClass:"dropdown-item is-size-6",class:{ 'is-active': _vm.$route.name === 'account-details' },attrs:{"to":{ name: 'account-details' }},nativeOn:{"click":function($event){return _vm.track('go_to_account_details')}}},[_c('span',[_vm._v("Edit profile")])]),(_vm.isBranchAdmin && _vm.branchHasAdmin)?_c('router-link',{staticClass:"dropdown-item is-size-6",class:{
              'is-active': _vm.$route.name === 'account-branch-admin'
            },attrs:{"to":{ name: 'account-branch-admin' }},nativeOn:{"click":function($event){return _vm.track('go_to_account_branch_admin')}}},[_c('span',[_vm._v("Manage branches")])]):_vm._e(),_c('hr',{staticClass:"dropdown-divider"}),_c('router-link',{staticClass:"dropdown-item is-size-6",class:{
              'is-active': _vm.$route.name === 'account-activity-user'
            },attrs:{"to":{ name: 'account-activity-user' }},nativeOn:{"click":function($event){return _vm.track('go_to_account_user_activity')}}},[_c('span',[_vm._v("My activity")])]),_c('router-link',{staticClass:"dropdown-item is-size-6",class:{
              'is-active': _vm.$route.name === 'account-activity-branch'
            },attrs:{"to":{ name: 'account-activity-branch' }},nativeOn:{"click":function($event){return _vm.track('go_to_account_branch_activity')}}},[_c('span',[_vm._v("Branch activity")])]),(_vm.organisation && _vm.isBranchAdmin && _vm.branchHasAdmin)?_c('router-link',{staticClass:"dropdown-item is-size-6",class:{
              'is-active': _vm.$route.name === 'account-activity-org'
            },attrs:{"to":{ name: 'account-activity-org' }},nativeOn:{"click":function($event){return _vm.track('go_to_account_org_activity')}}},[_c('span',[_vm._v("Organisation activity")])]):_vm._e(),(_vm.hasStockview)?_c('hr',{staticClass:"dropdown-divider"}):_vm._e(),_c('a',{staticClass:"dropdown-item is-size-6",on:{"click":_vm.logOut}},[_vm._m(1),_c('span',[_vm._v("Log out")])])],1)])])]):_vm._e()],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small is-marginless"},[_c('i',{staticClass:"fas fa-caret-down",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"icon is-small has-text-soft"},[_c('i',{staticClass:"fal fa-sign-out"})])
}]

export { render, staticRenderFns }