<template>
  <div
    class="navbar-end"
    v-if="!isIntegration && isUser"
  >
    <AppHeaderEndSwitch v-if="!$motors" />
    <AppHeaderEndLinks v-if="$motors" />
    <div
      class="navbar-menu"
      v-if="isUser"
      data-test-id="username-dropdown"
    >
      <div class="navbar-link dropdown is-hoverable is-arrowless is-right">
        <div class="dropdown-trigger">
          <a
            class="nav-menu-item"
            :to="{ name: 'account-details' }"
            :class="{ 'is-active': $route.name === 'account-details' }"
          >
            <span>
              <strong>
                {{ initials }}
              </strong>
            </span>
            <span class="icon is-small is-marginless">
              <i
                class="fas fa-caret-down"
                aria-hidden="true"
              />
            </span>
          </a>
        </div>
        <div
          class="dropdown-menu has-arrow is-right"
          role="menu"
        >
          <div class="dropdown-content">
            <router-link
              class="dropdown-item is-size-6"
              @click.native="track('go_to_account_details')"
              :to="{ name: 'account-details' }"
              :class="{ 'is-active': $route.name === 'account-details' }"
            >
              <span>Edit profile</span>
            </router-link>
            <router-link
              v-if="isBranchAdmin && branchHasAdmin"
              class="dropdown-item is-size-6"
              @click.native="track('go_to_account_branch_admin')"
              :to="{ name: 'account-branch-admin' }"
              :class="{
                'is-active': $route.name === 'account-branch-admin'
              }"
            >
              <span>Manage branches</span>
            </router-link>
            <hr class="dropdown-divider">
            <router-link
              class="dropdown-item is-size-6"
              @click.native="track('go_to_account_user_activity')"
              :to="{ name: 'account-activity-user' }"
              :class="{
                'is-active': $route.name === 'account-activity-user'
              }"
            >
              <span>My activity</span>
            </router-link>
            <router-link
              class="dropdown-item is-size-6"
              @click.native="track('go_to_account_branch_activity')"
              :to="{ name: 'account-activity-branch' }"
              :class="{
                'is-active': $route.name === 'account-activity-branch'
              }"
            >
              <span>Branch activity</span>
            </router-link>
            <router-link
              v-if="organisation && isBranchAdmin && branchHasAdmin"
              class="dropdown-item is-size-6"
              @click.native="track('go_to_account_org_activity')"
              :to="{ name: 'account-activity-org' }"
              :class="{
                'is-active': $route.name === 'account-activity-org'
              }"
            >
              <span>Organisation activity</span>
            </router-link>
            <hr
              class="dropdown-divider"
              v-if="hasStockview"
            >
            <a
              class="dropdown-item is-size-6"
              @click="logOut"
            >
              <span class="icon is-small has-text-soft">
                <i class="fal fa-sign-out" />
              </span>
              <span>Log out</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { logOut } from 'modules/auth/services'
export default {
  name: 'AppHeaderEnd',
  components: {
    AppHeaderEndSwitch: () => import('./AppHeaderEndSwitch'),
    AppHeaderEndLinks: () => import('./AppHeaderEndLinks'),
  },
  computed: {
    ...mapGetters('auth', [
      'isUser',
      'initials',
      'isBranchAdmin',
      'branchHasAdmin',
      'organisation',
      'isIntegration',
      'hasStockview'
    ])
  },
  methods: {
    track(action) {
      this.$mxp.track(`app_header_${action}`)
    },
    logOut() {
      this.track('logout')
      logOut()
    }
  }
}
</script>
